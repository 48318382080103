import { DynamicActionsConfig } from './types'

const CHAT_APP_DEF_ID = '14517e1a-3ff0-af98-408e-2bd6953c36a2'

export const dynamicActionsConfig: DynamicActionsConfig = {
	[CHAT_APP_DEF_ID]: {
		iconSvgContent: `<svg viewBox="17 8 17 17.989" width="50" height="30" xmlns="http://www.w3.org/2000/svg"><g><path d="M19 8c-1.105 0-2 .892-2 2.005v9.577a2 2 0 0 0 1.99 2.004h2.238l-.18 3.847c-.027.555.3.724.736.371L27 21.586h4.994A2.001 2.001 0 0 0 34 19.582v-9.577C34 8.897 33.11 8 32 8H19z" fill-rule="evenodd"/></g></svg>`,
		color: '#2D41A9',
		text: 'Chat',
		itemType: 'chat',
	},
}

import { named, optional, withDependencies } from '@wix/thunderbolt-ioc'
import { PageFeatureConfigSymbol, FeatureStateSymbol, Props, IPropsStore, pageIdSym } from '@wix/thunderbolt-symbols'
import { QuickActionBarProps } from '@wix/thunderbolt-components'
import { color as colorUtils } from '@wix/thunderbolt-commons'
import { ITPAEventsListenerManager, TpaEventsListenerManagerSymbol } from 'feature-tpa'
import { IFeatureState } from 'thunderbolt-feature-state'

import {
	QuickActionBarPageConfig,
	QuickActionBarState,
	IDynamicActionsApi,
	DynamicActionsConfig,
	DynamicAction,
} from './types'
import { name, DynamicActionsConfigSymbol } from './symbols'

type DynamicActionItemProps = Required<QuickActionBarProps>['dynamicActions'][0]
type DynamicActionsState = QuickActionBarState['dynamicActions']

const dynamicActionsApiFactory = (
	{ componentId }: QuickActionBarPageConfig,
	featureState: IFeatureState<QuickActionBarState>,
	propsStore: IPropsStore,
	dynamicActionsConfig: DynamicActionsConfig,
	pageId: string,
	tpaEventsListenerManager?: ITPAEventsListenerManager
): IDynamicActionsApi => {
	let localDynamicActions: DynamicActionsState = []

	const triggerQuickActionEvent = (quickAction: string) => {
		if (tpaEventsListenerManager) {
			tpaEventsListenerManager.dispatch('QUICK_ACTION_TRIGGERED', () => ({ quickAction }))
		}
	}

	const updateQABComponentProps = () => {
		const state = featureState.get()
		const globalDynamicActions = state?.dynamicActions ?? []

		const dynamicActions = globalDynamicActions
			.concat(localDynamicActions)
			.filter(({ isVisible = true }) => isVisible)
			.map<DynamicActionItemProps>(({ appDefinitionId, hasNotifications = false, color, iconSvgContent }) => {
				const dynamicActionConfig = dynamicActionsConfig[appDefinitionId]
				const {
					text,
					itemType,
					color: defaultColor,
					iconSvgContent: defaultIconSvgContent,
				} = dynamicActionConfig
				const colorAsRgbValues =
					(color && colorUtils.getSplitRgbValuesString(color)) ||
					colorUtils.getSplitRgbValuesString(defaultColor)!

				return {
					appDefinitionId,
					text,
					itemType,
					hasNotifications,
					color: colorAsRgbValues,
					svgString: iconSvgContent || defaultIconSvgContent,
					onActivate() {
						triggerQuickActionEvent(appDefinitionId)
					},
				}
			})

		propsStore.update({
			[componentId]: {
				dynamicActions,
			},
		})
	}

	const getUpdatedDynamicActionsState = (action: DynamicAction, dynamicActions: Readonly<DynamicActionsState>) => {
		const existingActionIndex = dynamicActions.findIndex((da) => da.appDefinitionId === action.appDefinitionId)
		const existingAction = existingActionIndex !== -1 ? dynamicActions[existingActionIndex] : undefined
		const updatedAction = { ...existingAction, ...action }
		const updatedDynamicActions = dynamicActions.slice()

		if (existingAction) {
			updatedDynamicActions[existingActionIndex] = updatedAction
		} else {
			updatedDynamicActions.push(updatedAction)
		}

		return updatedDynamicActions
	}

	return {
		updateAction(action) {
			const { appDefinitionId } = action

			if (!(appDefinitionId in dynamicActionsConfig)) {
				throw new Error(`Dynamic actions are not supported for app definition id: "${appDefinitionId}"`)
			}

			if (pageId === 'masterPage') {
				featureState.update((state?: Partial<QuickActionBarState>) => {
					const dynamicActions = getUpdatedDynamicActionsState(action, state?.dynamicActions ?? [])
					return {
						...state,
						dynamicActions,
					}
				})
			} else {
				localDynamicActions = getUpdatedDynamicActionsState(action, localDynamicActions)
			}

			updateQABComponentProps()
		},
		updateQABComponentProps,
	}
}

export const DynamicActionsApi = withDependencies(
	[
		named(PageFeatureConfigSymbol, name),
		named(FeatureStateSymbol, name),
		Props,
		DynamicActionsConfigSymbol,
		pageIdSym,
		optional(TpaEventsListenerManagerSymbol),
	],
	dynamicActionsApiFactory
)

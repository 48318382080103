import { ContainerModuleLoader } from '@wix/thunderbolt-ioc'
import { TpaHandlerProviderSymbol, LifeCycle } from '@wix/thunderbolt-symbols'
import { QuickActionBar } from './quickActionBar'
import { TpaHandlerProvider } from './tpaHandlers'
import { DynamicActionsApi } from './dynamicActionsApi'
import { dynamicActionsConfig } from './dynamicActionsConfig'
import { DynamicActionsApiSymbol, DynamicActionsConfigSymbol } from './symbols'

export const page: ContainerModuleLoader = (bind, bindAll) => {
	bindAll([LifeCycle.PageWillMountHandler, LifeCycle.PageDidUnmountHandler], QuickActionBar)
	bind(TpaHandlerProviderSymbol).to(TpaHandlerProvider)
	bind(DynamicActionsApiSymbol).to(DynamicActionsApi)
	bind(DynamicActionsConfigSymbol).toConstantValue(dynamicActionsConfig)
}

import { withDependencies } from '@wix/thunderbolt-ioc'
import { TpaHandler } from '@wix/thunderbolt-symbols'
import { IDynamicActionsApi, DynamicAction } from './types'
import { DynamicActionsApiSymbol } from './symbols'

export type SetMobileActionBarButtonMessageData = {
	notifications?: boolean
	visible?: boolean
	color?: string
	iconSvgContent?: string
}

const tpaHandlerProviderFactory = (dynamicActionsApi: IDynamicActionsApi) => ({
	getTpaHandlers() {
		const setMobileActionBarButton: TpaHandler = async (
			compId,
			{ notifications, visible, color, iconSvgContent }: SetMobileActionBarButtonMessageData,
			{ appDefinitionId }
		) => {
			const action: DynamicAction = {
				appDefinitionId,
				...(typeof notifications === 'boolean' && { hasNotifications: notifications }),
				...(typeof visible === 'boolean' && { isVisible: visible }),
				...(typeof color === 'string' && { color }),
				...(typeof iconSvgContent === 'string' && { iconSvgContent }),
			}

			const shouldUpdateAction = Object.values(action).length > 1

			if (shouldUpdateAction) {
				dynamicActionsApi.updateAction(action)
			}
		}

		return {
			setMobileActionBarButton,
		}
	},
})

export const TpaHandlerProvider = withDependencies([DynamicActionsApiSymbol], tpaHandlerProviderFactory)

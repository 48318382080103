import { withDependencies, named } from '@wix/thunderbolt-ioc'
import {
	PageFeatureConfigSymbol,
	IPageWillMountHandler,
	IPageDidUnmountHandler,
	Props,
	IPropsStore,
	BrowserWindow,
	BrowserWindowSymbol,
} from '@wix/thunderbolt-symbols'
import { SiteScrollBlockerSymbol, ISiteScrollBlocker, IScrollBlockedListener } from 'feature-site-scroll-blocker'
import { QuickActionBarPageConfig, IDynamicActionsApi } from './types'
import { name, DynamicActionsApiSymbol } from './symbols'
import { isSafari, isChromeOnIos, isIOS11Device } from '@wix/thunderbolt-commons'

const quickActionBarFactory = (
	{ componentId }: QuickActionBarPageConfig,
	propsStore: IPropsStore,
	siteScrollBlocker: ISiteScrollBlocker,
	window: BrowserWindow,
	dynamicActions: IDynamicActionsApi
): IPageWillMountHandler & IPageDidUnmountHandler => {
	let scrollBlockedListenerId: unknown

	const onScrollBlocked: IScrollBlockedListener = {
		handleBlockedBy(blockedCompId) {
			if (blockedCompId !== componentId) {
				propsStore.update({ [componentId]: { isHidden: true } })
			}
		},
		handleUnblockedBy(unblockedCompId) {
			if (unblockedCompId !== componentId) {
				propsStore.update({ [componentId]: { isHidden: false } })
			}
		},
	}

	const shouldSetPositionAbsoluteAtPageBottom =
		isIOS11Device(window!) && (isChromeOnIos(window!) || isSafari(window!))

	return {
		async pageWillMount() {
			propsStore.update({
				[componentId]: {
					onOpen() {
						siteScrollBlocker.setSiteScrollingBlocked(true, componentId)
					},
					onClose() {
						siteScrollBlocker.setSiteScrollingBlocked(false, componentId)
					},
					shouldSetPositionAbsoluteAtPageBottom,
				},
			})

			dynamicActions.updateQABComponentProps()
			scrollBlockedListenerId = siteScrollBlocker.registerScrollBlockedListener(onScrollBlocked)
		},
		async pageDidUnmount() {
			siteScrollBlocker.unRegisterScrollBlockedListener(scrollBlockedListenerId)
		},
	}
}

export const QuickActionBar = withDependencies(
	[
		named(PageFeatureConfigSymbol, name),
		Props,
		SiteScrollBlockerSymbol,
		BrowserWindowSymbol,
		DynamicActionsApiSymbol,
	],
	quickActionBarFactory
)
